import styled from "styled-components"

export const StyledOfferItems = styled.section``

export const OfferSingleItem = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.text};
  padding: 4rem 0;

  @media ${props => props.theme.sizes.desktop} {
    padding: 6rem 0;
  }

  &:nth-of-type(2n) {
    .singleItem__image {
        grid-area: 1 / 1 / 2 / 2;
    }
    .singleItem__content {
        grid-area: 1 / 2 / 2 / 3;
    }
  }

  .singleItem {
    &__wrapper {
      display: flex;
      flex-direction: column;

      @media ${props => props.theme.sizes.tablet} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 200px;
        grid-row: 1;
      }
    }
    &__content {
        @media ${props => props.theme.sizes.tablet} {
          padding-right: 100px;
        }
    }
    &__desc {
        font-family: ${props => props.theme.fonts.secondary};
        font-weight: 300;
        line-height: 30px;
    }

    &__list {
      font-size: 1.15rem;
      line-height: 1;
      z-index: 10;

      ul {
        list-style: none;
        padding-left: 0;

        li {
            position: relative;
            padding: 20px 0;
            border-bottom: 1px solid black;
            margin-right: 1rem;

            @media ${props => props.theme.sizes.tablet} {
              margin-right: 20%;
            }

        }

        br {
          display: none;
        }
      }
      a {
        color: ${props => props.theme.colors.black};
      }
    }
    &__title {
      font-weight: 500;
      font-size: 2rem;

      color: ${props => props.theme.colors.black};
      text-transform: lowercase;
      margin-bottom: 1rem;
      z-index: 10;
      display: flex;
      flex-direction: column;

      .bold {
        font-weight: 800;
      }

      @media ${props => props.theme.sizes.tablet} {
        font-size: 4.4rem;
        line-height: 87px;
      }
    }
    &__desc {
      font-size: 1rem;
      color: ${props => props.theme.colors.black};

      @media ${props => props.theme.sizes.tablet} {
        font-size: 1.1rem;
      }
    }
    &__links {
      display: flex;
      flex-direction: column;

      .link {
        font-size: 1rem;
        padding: .3rem 0;
        font-weight: 700;
        text-decoration: none;
        color: ${props => props.theme.colors.text};

        @media ${props => props.theme.sizes.tablet} {
          font-size: 20px;
        }
      }
    }
    &__image {
      display: none;

      @media ${props => props.theme.sizes.tablet} {
        display: block;
      }
    }
  }
`