import React from "react"


import Container from "../../container"
import { StyledOfferItems, OfferSingleItem } from "./OfferItems.style"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SectionTitle from "../../section-title"

const OfferItems = props => {


  const elements = props.items;

  console.log(elements)

  const OfferItem = elements.map((item, index) => {
    let remove_after = item.title.indexOf("_")
    let ThinTitle = item.title.substring(0, remove_after + 1)
    let BoldTitle = item.title.substring(remove_after + 1)


    const Links = item.ACF_offer.links.map((element, index) => (
        <a className="link" key={index} href={element.link.url} target={element.link.target}>{element.link.title} <span className="arrow"> ></span></a>
    ))

    return (
      <OfferSingleItem key={index} id={item.slug}>
        <div className="singleItem__wrapper">
          <div className="singleItem__content">

            <h3 className="singleItem__title">
              {ThinTitle}
              <span className="bold">{BoldTitle}</span>
            </h3>
            <div
              className="singleItem__list"
              dangerouslySetInnerHTML={{ __html: item.ACF_offer.list }}
            />
            <div
              className="singleItem__desc"
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
            <div className="singleItem__links">
            {Links}
            </div>

          </div>
          <div className="singleItem__image">
              <GatsbyImage
                  image={getImage(item.featuredImage.node.localFile)}
                  alt={item.featuredImage.node.alt}
                  title={item.featuredImage.node.title}
              />
          </div>
        </div>
      </OfferSingleItem>
    )
  })

  return (
    <StyledOfferItems>
      <Container>
          {OfferItem}
      </Container>
    </StyledOfferItems>
  )
}

export default OfferItems
