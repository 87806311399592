import React from "react"
import styled from "styled-components"
import Container from "../../Container"
import AnimatedSquare from "../../animated-square"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BannerImage = props => {
  const StyledBannerImage = styled.section`
    min-height: 50vw;
    position: relative;
    overflow: hidden;

    .bannerImage {
      &__wrapper {
        min-height: 50vw;
        /* display: flex;
        align-items: center; */
      }
      &__content {
        background: white;
        padding-top: 10vh;

        @media ${props => props.theme.sizes.laptop} {
          padding-top: 20vh;
          max-width: 50%;
        }
      }
      &__title {
        font-family: ${props => props.theme.fonts.primary};
        font-size: 45px;
        color: ${props => props.theme.colors.primary};
        font-weight: 500;
        display: flex;
        flex-direction: column;

        @media ${props => props.theme.sizes.laptop} {
          font-size: 100px;
          line-height: 109px;
          margin: 50px 0;
        }
      }
      &__text {
        font-family: ${props => props.theme.fonts.secondary};
        font-weight: 300;


        @media ${props => props.theme.sizes.laptop} {
          font-size: 25px;
          line-height: 41px;
        }
      }
    }
  `


  let remove_after = props.title.indexOf("_")
  let ThinTitle = props.title.substring(0, remove_after + 1)
  let BoldTitle = props.title.substring(remove_after + 1)

  return (
    <StyledBannerImage>
      <Container>
        <div className="bannerImage__wrapper">
          <div className="bannerImage__content">
            <h1 className="bannerImage__title">
              {ThinTitle}
              <strong>{BoldTitle}</strong>
            </h1>
            <div className="bannerImage__text">{props.text}</div>
          </div>
          <AnimatedSquare
                width="70rem"
                height="70rem"
                top="-35rem"
                right="-20rem"
                phoneTop="-35rem"
            />
        </div>
      </Container>
    </StyledBannerImage>
  )
}

export default BannerImage
