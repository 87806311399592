import React, { useRef, useEffect } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

import styled from "styled-components"

const AnimatedSquare = props => {


  gsap.registerPlugin(ScrollTrigger)

  const revealRef = useRef([])
  revealRef.current = []

  const StyledSquare = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
    position: relative;
    background: transparent;
    transform: rotate(65deg);
    position: absolute;
    top: ${props => props.phoneTop || props.top};
    bottom: ${props => props.phoneBottom || props.bottom};
    left: ${props => props.phoneLeft || props.left};
    right: ${props => props.phoneRight || props.right};
    z-index: -1;

    @media ${props => props.theme.sizes.mobileL} {
      top: -25rem;
    }

    @media ${props => props.theme.sizes.tablet} {
      top: ${props => props.top};
      bottom: ${props => props.bottom};
      left: ${props => props.left};
      right: ${props => props.right};
    }

    .line-1,
    .line-2 {
      width: 0%;
      position: absolute;
      height: 0.55rem;
      background-color: ${props => props.theme.colors.gray};
    }
    .line-3,
    .line-4 {
      width: 0.55rem;
      position: absolute;
      height: 0%;
      background-color: ${props => props.theme.colors.gray};
    }
    .line {
      &-1 {
        top: 0;
        left: 0%;
      }
      &-2 {
        bottom: 0;
        right: 0;
      }
      &-3 {
        right: 0;
      }
      &-4 {
        left: 0;
        bottom: 0;
      }
    }

    &.ScrollTrigger {
      background: #fff;
      .line {
        &-1 {
          transition: width 1s ease-out 0s;
          width: 100%;
        }
        &-2 {
          transition: width 1s ease-out 2s;
          width: 100%;
        }
        &-3 {
          transition: height 1s ease-out 1s;
          height: 100%;
        }
        &-4 {
          transition: height 1s ease-out 3s;
          height: 100%;
        }
      }
    }
  `
  const ref = useRef(null)

  useEffect(() => {

      revealRef.current.forEach((el, index) => {
        gsap.fromTo(
          el,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: props => props.opacity || 1,
            ease: "none",
            scrollTrigger: {
              id: `section-${index + 1}`,
              trigger: el,
              start: "top",
              toggleClass: "ScrollTrigger",
            },
          }
        )
      })
  }, [])

  const addToRefs = el => {
    if (el && !revealRef.current.includes(el)) {
      revealRef.current.push(el)
    }
  }

  return (
    <StyledSquare
      ref={addToRefs}
      className="AnimatedSquare"
      width={props.width}
      height={props.height}
      top={props.top}
      bottom={props.bottom}
      right={props.right}
      left={props.left}
      phoneTop={props.phoneTop}
      phoneBottom={props.phoneBottom}
      phoneRight={props.phoneRight}
      phoneLeft={props.phoneLeft}
      opacity={props.opacity}
    >
      <div className="line line-1"></div>
      <div className="line line-2"></div>
      <div className="line line-3"></div>
      <div className="line line-4"></div>
    </StyledSquare>
  )
}

export default AnimatedSquare
