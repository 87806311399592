import React from "react"
import Layout from "../../components/layout"
import { SEO } from "../../components/seo"
import BannerImage from "../../components/organisms/OffertPage/BannerImage"
import OfferItems from "../../components/organisms/OffertPage/OfferItems"

const Page = props => {
  const ACF = props.pageContext.ACF_page_oferta
  const SEO = props.pageContext.seo


  return (
    <Layout seoData={SEO} seoUrl={props.pageContext.uri} overflow='hidden'>
      <BannerImage
        title={ACF.pageTitle}
        text={ACF.pageText}
        imageSrc={ACF.bannerImage.localFile}
        imageAlt={ACF.bannerImage.altText}
        imageTitle={ACF.bannerImage.title}
      />
      <OfferItems items={ACF.offerItems} />
    </Layout>
  )
}
export default Page

export const Head = props => {
  return (
   <SEO title={props.pageContext.seo.title} description={props.pageContext.seo.opengraphDescription} image={props.pageContext.seo?.opengraphImage?.localFile.childImageSharp.fixed.src} pathname={props.pageContext.seo.opengraphUrl} />
  )
}
